/* Main Container */
.cfc-container {
  text-align: center;
  padding: 40px 0;
  background-color: #f4f4f2;
  margin-top: 2rem;
  border-radius: 8px;
}

/* Title Section */
.cfc-title-container {
  margin-bottom: 30px;
}

.cfc-title {
  font-weight: 700;
  color: #333;
  font-size: 2rem;
}

.cfc-subtitle {
  font-size: 1rem;
  color: #666;
  margin-top: 5px;
}

/* Card Layout */
.cfc-card-row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.cfc-card-col {
  display: flex;
  justify-content: center;
}

/* Card */
.cfc-card {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: none;
}

.cfc-card:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

/* Card Image */
.cfc-card-img {
  height: 220px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* Card Body */
.cfc-card-body {
  padding: 20px;
  text-align: left;
}

.cfc-card-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: #333;
}

.cfc-card-text {
  font-size: 0.9rem;
  color: #666;
}

/* Explore More Action */
.cfc-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 15px;
  transition: color 0.3s ease-in-out;
}

.cfc-action:hover {
  color: #dc2329;
}

.cfc-explore-text {
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 8px;
}

.cfc-arrow-icon {
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cfc-card {
    height: auto;
  }

  .cfc-card-img {
    height: 180px;
  }

  .cfc-title {
    font-size: 1.7rem;
  }

  .cfc-subtitle {
    font-size: 0.9rem;
  }
}
