.main-content-first-row {
  margin-top: 2rem;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.main-content-hero-container {
  height: 100%;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}

.main-content-hero-content {
  background: linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.main-content-hero-text {
  padding: 1rem;
}

.main-content-heading {
  font-size: 2.5rem;
  font-weight: 800;
  color: #2d3436;
  margin: 0;
  line-height: 1.2;
}

.main-content-heading-accent {
  font-size: 2.5rem;
  font-weight: 800;
  color: #cb202d;
  margin: 0;
  line-height: 1.2;
}

.main-content-subheading {
  font-size: 1.2rem;
  color: #636e72;
  margin-top: 1rem;
}

.main-content-cta-container {
  padding: 1rem;
}

.main-content-shop-button {
  background: #cb202d;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(203, 32, 45, 0.2);
}

.main-content-shop-button:hover {
  background: #a61821;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(203, 32, 45, 0.3);
}

.main-content-arrow-icon {
  transition: transform 0.3s ease;
}

.main-content-shop-button:hover .main-content-arrow-icon {
  transform: translateX(5px);
}

.main-content-image-container {
  height: 100%;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease-out;
}

.main-content-feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.main-content-feature-image:hover {
  transform: scale(1.02);
}

.main-content-image-stack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-content-image-stack-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease-out;
}

.main-content-stack-item {
  height: 48%;
}

@media (max-width: 767px) {
  .main-content-hero-content {
    padding: 2rem 1.5rem;
  }

  .main-content-heading,
  .main-content-heading-accent {
    font-size: 2rem;
  }

  .main-content-subheading {
    font-size: 1rem;
  }

  .main-content-shop-button {
    width: 100%;
    justify-content: center;
  }

  .main-content-stack-item {
    height: 200px;
  }
}
