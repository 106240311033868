.product-container {
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.product-box {
  position: relative;
  width: 220px;
  height: 220px;
  background: #c8c8c866; /* Box color */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  transition: background 0.3s ease-in-out;
}

/* Image Styling */
.product-image {
  width: 80%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  mix-blend-mode: multiply;
}

.product-container:hover .product-image {
  transform: scale(1.05);
}

/* Add to Cart Button */
.add-to-cart-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #111;
  color: white;
  border: none;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.add-to-cart-btn.show {
  transform: translateY(0);
  opacity: 1;
}

/* Product Name */
.product-name {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 10px;
  color: #333;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
}

.product-container:hover .product-name {
  color: #111;
}
