/* Main Container */
.prd-details-container {
  max-width: 1440px;
  margin: 2rem auto;
  padding: 0 1rem;
}

/* Breadcrumb */
.prd-details-breadcrumb {
  font-size: 0.875rem;
  color: #484848;
  margin-bottom: 1.5rem;
}

/* Left Panel */
.prd-details-section {
  background: #fff;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.prd-details-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111;
  margin: 1rem 0;
}

.prd-details-description {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #484848;
  margin-bottom: 1rem;
}

.prd-details-features {
  margin: 1.25rem 0;
}
.prd-details-heading {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  font-weight: 500;
  color: #111;
  margin-bottom: 0.75rem;
}

/* Right Panel */
.prd-details-customization {
  background: #fff;
  border-radius: 8px;
  padding: 1rem 2rem;
  border: 1px solid #f0f0f0;
}

.prd-details-section-spacer {
  margin-bottom: 0.5rem;
  /* padding-bottom: 0.5rem; */
  border-bottom: 1px solid #f0f0f0;
}

.prd-details-section-title {
  font-size: 1rem;
  font-weight: 500;
  color: #111;
  margin-bottom: 0.75rem;
}

/* Color Selection */
.prd-details-color-options {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.prd-details-color-item {
  position: relative;
  cursor: pointer;
}

.prd-details-color-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 0.2s ease;
}

.prd-details-color-selected .prd-details-color-circle {
  border-color: #111;
  transform: scale(1.1);
}

/* Size Selection */
.prd-details-size-options {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.prd-details-size-item {
  padding: 0.375rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.8125rem;
}

.prd-details-size-selected {
  background: #111;
  color: #fff;
  border-color: #111;
}

/* Pattern Selection */
.prd-details-pattern-options {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.prd-details-pattern-item {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease;
}

.prd-details-pattern-selected {
  border-color: #111;
  transform: scale(1.05);
}

.prd-details-pattern-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Input Fields */
.prd-details-input-field {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  transition: border-color 0.2s ease;
}

.prd-details-input-field:focus {
  border-color: #111;
  outline: none;
}

/* Purchase Section */
.prd-details-purchase {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid #f0f0f0;
}

.prd-details-price {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.prd-details-cart-btn {
  width: 100%;
  padding: 0.75rem;
  background: #111;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background 0.2s ease;
}

.prd-details-cart-btn:hover {
  /* background: #d32c29; */
  opacity: 0.8;
}

/* Image Gallery */
.prd-details-carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

/* Responsive */
@media (max-width: 768px) {
  .prd-details-container {
    margin: 1rem auto;
  }

  .prd-details-customization {
    padding: 1rem;
  }

  .prd-details-section-spacer {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .prd-details-title {
    font-size: 1.25rem;
  }
}
