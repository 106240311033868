.footer {
  background-color: #000;
  color: #fff;
  margin-top: 3rem;
  padding-top: 5rem;
}

.footer a {
  color: #bbb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #fff;
}

.footer h5 {
  font-weight: bold;
  margin-bottom: 15px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 8px;
}

.footer-contact p {
  margin-bottom: 10px;
}

.footer .social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.footer .social-icons a {
  font-size: 20px;
  color: #bbb;
  transition: color 0.3s ease;
}
/* #footer-link-icon-bg {
  background: white;
  padding: 5px;
}
#footer-link-icon-bg:hover {
  color: black;
} */

.footer .social-icons a:hover {
  color: #fff;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .footer-top {
    text-align: center;
  }

  .footer-links,
  .footer-contact {
    margin-top: 20px;
  }

  .footer .social-icons {
    justify-content: center;
  }
}
