.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
}
.BUTTON {
  border: none;
  background-color: #111;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  width: 90px;
  margin-bottom: 20px;
}
