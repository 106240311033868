/* Full-screen loader container */
.main-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(88, 88, 88, 0.4); /* Soft dark slate grey */
  backdrop-filter: blur(1px);
  z-index: 9999;
}
