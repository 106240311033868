:root {
  --montserrat: "Montserrat", sans-serif;
  --inter: "Inter", sans-serif;
  --poppins: "Poppins", sans-serif;
}
body {
  margin: 0;
  font-family: var(--montserrat);
  font-optical-sizing: auto;
  scrollbar-width: none !important;
}
html {
  scrollbar-width: none !important;
}
::-webkit-scrollbar {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
