#backGround {
  background-color: #fff !important;
  width: 100%;
}
.custom-placeholder::placeholder {
  color: black;
}
#fileUpload {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dashboardModalHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-placeholder {
  background-color: #e4cdaa;
  color: #642315e5;
  border: 1px solid #642315e5;
}
#orderModal {
  border-radius: 1rem;
}
.sendotp {
  display: flex;
  justify-content: right;
  gap: 2rem;
  margin-bottom: 1rem;
}
.otp {
  color: black;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
}
.resendotp {
  color: black;
  font-weight: 500;
  cursor: pointer;
}
.resendotp {
  margin: 0;
}
.rudrainput {
  /* padding: 3rem; */
  width: 100%;
}
.mainmodalbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.lastbuttonmodal {
  font-weight: 500;
  background-color: #c99f62;
  color: #642315e5;
  line-height: 1.5;
  width: 100%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastbuttonmodal:hover {
  background-color: #c99f62;
  color: #642315e5;
}

.modalheaderm {
  background-color: #c99f62;
  color: red;
  color: #6b2213;
}

.cutomizedrow {
  border: 1px solid #514027;
  border-radius: 14px;
  background-color: #e4cdaa;
  color: #642315;
  padding: 1rem;
}

.modalparagraph {
  text-align: center;
  margin: 0;
}
.modalparagraphhh {
  text-align: center;
  margin: 0;
  margin-top: -1vh;
}

.mainmodalcol {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* SuccessModal.css */
.custom-modal {
  max-width: 800px;
}

@media (min-width: 576px) {
  .custom-modal {
    max-width: 700px;
  }
}

@media (min-width: 768px) {
  .custom-modal {
    max-width: 800px;
  }
}
.estimated-date-container:disabled {
  background-color: #e4cdaa;
  cursor: not-allowed;
}
.custom-placeholder:disabled {
  background-color: #e4cdaa;
  cursor: not-allowed;
}
.estimated-date-container {
  background-color: #e4cdaa;
  color: #642315e5;
  border: 1px solid #642315e5;
}
.estimated-date-container:focus {
  background-color: #e4cdaa;
}
.estimatedDate {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #642315;
  background-color: #e4cdaa;
  border: none;
  outline: none;
}

.get-details-btn {
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: #642315;
  border: 1px solid #642315;
  outline: none;
  width: 7rem;
}
.get-details-btn:hover {
  background-color: #642315;
  border: 1px solid #642315;
  outline: none;
  opacity: 90%;
  color: white;
}
.get-details-btn:focus {
  background-color: #642315;
  border: 1px solid #642315;
  outline: none;
  opacity: 90%;
}
.get-details-btn:active {
  background-color: #642315;
  border: 1px solid #642315;
  outline: none;
  opacity: 90%;
}
