.category-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  margin: 5rem 0rem;
}

.category-col {
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.category-image-container {
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  border-radius: 50%;
}

.category-image {
  width: 100%;

  height: auto;
  object-fit: contain;
  mix-blend-mode: multiply;
  transition: all 0.4s ease;
}
.category-image:hover {
  transform: scale(1.05) translateY(-8px);
}

.category-name {
  margin-top: 10px;
  font-weight: 500;
  color: #333;
}

/* Highlight selected category */
.category-card.selected .category-image-container {
  background-color: #c8c8c866;
  padding: 1rem;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .category-image-container {
    width: 100px;
    height: 100px;
  }

  .category-name {
    font-size: 0.9rem;
  }
}
