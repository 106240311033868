.rw {
  border-bottom: 2px solid #2d2d2d;
  background-color: #f4f4f2;
  padding: 50px;
}
.heading-text {
  color: #2d2d2d;
}
.rw-3 {
  background-color: #fefae4;
  padding: 50px;
}
.content {
  background-color: #fefefe;
  border-radius: 5px;
  border: 1px solid #2d2d2d;
}

.heading {
  border-bottom: 1px solid #2d2d2d;
}

#editBtn {
  background-color: #fefae4;
  color: #2d2d2d;
  width: 101px;
  font-size: 0.7rem;
  margin-top: 0.3rem;
}

.dashHeading {
  font-weight: 400;
}
.dashSubHeading {
  font-weight: 600;
}
.dashPara {
  color: black;
  font-weight: 400;
}

.personalInfo {
  color: black;
  font-weight: 600;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}

.personalInfo span {
  font-weight: 300;
}
.detailIcon {
  cursor: pointer;
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.dashboardImg {
  width: 110px;
  border-radius: 0.3rem;
  height: auto;
  object-fit: cover;
}
.deliveryDetailsStatus {
  display: flex;
  justify-content: end;
}
.statusPosition {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media (min-width: 580px) {
  .statusPosition {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
