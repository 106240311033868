.dream-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  background: none;
  padding: 10px;
}

#breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #dc2329 !important; /* Changed from #111 to #dc2329 */
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

#breadcrumb-item:hover {
  color: #dc2329; /* Changed from #0056b3 to maintain consistency */
}

.breadcrumb-icon {
  font-size: 18px;
}

.breadcrumb-active {
  color: #333;
  font-weight: bold;
  cursor: default;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dream-breadcrumb {
    font-size: 14px;
    padding: 5px;
  }

  .breadcrumb-icon {
    font-size: 16px;
  }
}
