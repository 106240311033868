.contact-us-heading {
  margin-left: 2rem;
}

.contact-us-body {
  padding: 2.5rem;
  border-radius: 1rem !important;
}

.contact-us-input {
  width: 100%;
  border-radius: 0.7rem;
  padding: 10px;
}

#contact-us-button {
  background-color: #111;
  border: 1px solid #fff;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#contact-us-modal {
  border-radius: 12px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .contact-us-body {
    padding: 1.5rem;
  }
}
