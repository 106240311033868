.scc-second-row {
  border-top: 2px solid #f4f4f2;
  padding-top: 2rem;
}

.scc-title-container {
  margin: 2rem 0;
  text-align: center;
}

.scc-heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #251c1a;
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.scc-heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #dc2329;
}

.scc-image-stack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.scc-stack-item {
  height: 48%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.scc-single-image {
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.scc-feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.scc-feature-image:hover {
  transform: scale(1.05);
}

.scc-content-container {
  background: linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.scc-content {
  width: 100%;
  text-align: center;
}

.scc-main-heading h1 {
  font-size: 2.2rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 1.5rem;
}

.scc-subheading h5 {
  color: #dc2329;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.scc-description {
  color: #636e72;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 80%;
  margin-bottom: 1.5rem;
}

.scc-contact-text {
  color: #2d3436;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.scc-button-container {
  display: flex;
  justify-content: center;
}

.scc-contact-button {
  background: #000;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.scc-contact-button:hover {
  background: #1a1a1a;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .scc-heading {
    font-size: 2rem;
  }

  .scc-main-heading h1 {
    font-size: 1.8rem;
  }

  .scc-description {
    max-width: 100%;
  }

  .scc-content-container {
    padding: 1.5rem;
  }

  .scc-contact-button {
    width: 80%;
    padding: 0.8rem;
  }

  .scc-stack-item {
    height: 200px;
  }
}
