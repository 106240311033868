.productbutton p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
}
#arrowIcon {
  margin-left: 3px;
  margin: 0;
}

.productbutton {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  padding: 0.3vw;
  border-radius: 20px;
  background-color: #111;
  color: #fff;
  transition: all 0.3s ease;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  gap: 5px;
}

.arrow {
  transition: transform 0.5s ease;
  font-weight: 500;
}

.productbutton:hover .arrow {
  transform: translateX(5px);
}
@media only screen and (max-width: 767px) {
  #arrowIcon {
    margin-left: 3px;
    width: 15px;
  }
}
