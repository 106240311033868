.dreamimg {
  margin-top: 2.7vh;
}
.dreamcol1 {
  margin: 0;
  padding: 0;
  width: fit-content;
}
.browse {
  font-size: xx-large;
  color: #642315;
  font-weight: 500;
  margin: 0;
}
.circle1 {
  position: relative;
  width: 16%;
  /* padding-bottom:50%; */
  height: 5vh;
  background: #ffffff;
  border-radius: 50%;
}

.circle2 {
  position: relative;
  width: 16%;

  height: 5vh;
  background: #808080;
  border-radius: 50%;
}

.circle3 {
  position: relative;
  width: 16%;

  height: 5vh;
  background: #f5f5dc;
  border-radius: 50%;
}

.circle4 {
  position: relative;
  width: 16%;

  height: 5vh;
  background: #000080;
  border-radius: 50%;
}

.circlecontainer {
  /* padding: 0.2rem; */
  display: flex;
  align-items: center;
}

.ml-3 {
  margin-left: 1rem;
  margin-top: 2vh;
}

.measure {
  margin-left: 2vw;
}

.additinalinfo {
  width: 100%;
  background-color: #fefae4;
  border: none;
}
.money {
  margin-top: 2vh;
  font-weight: 700;
  font-size: x-large;
}
.dreambutton {
  width: 100%;
  padding: 0.7vw;
  border: none;
  border-radius: 14px;
  background-color: #9a5d24;
  color: white;
  font-weight: 700;
  font-size: 1.6rem;
}

.dreambredcrumb {
  font-weight: 500;
  margin-bottom: 1vh;
}

.features-title {
  font-weight: bold; /* Make the "Features" title bolder */
  margin-right: 0.5rem; /* Add some spacing between the title and list */
}

.features-list {
  margin-left: 1rem; /* Adjust the left padding of the list items */
  list-style-type: disc; /* Change the list style type if needed */
}
.features-container {
  margin-top: 10vh;
}
.topcontainer {
  margin-top: 5vh;
}
.line {
  border-bottom: 2px solid #c99f62;
  width: 100%;
  height: 0;
  margin-top: 4vh;
}
@media (max-width: 767.98px) {
  .maincol {
    margin-top: -2vh;
  }
  .topcontainer {
    margin-top: 0vh;
  }
  .dreambredcrumb {
    margin-top: 2vh;
    margin-bottom: 0vh;
  }
  .features-container {
    margin-top: 5vh;
  }
  .luxurycolcontain {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .luxurycolcontain > span {
    margin-bottom: 0.5rem;
  }
}
