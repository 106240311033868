.loginContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

#loginDiv {
  background-color: #f4f4f2;
  width: 40%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border-radius: 0.4rem;
}
#signupDiv {
  background-color: #f4f4f2;
  width: 40%;
  padding: 1rem 3rem;
  border-radius: 0.4rem;
  /* max-height: 95vh;
  overflow-y: scroll; */
}
#mmIcon {
  width: 150px;
  height: auto;
}

.input {
  background-color: #fbfbfa;
  border: 1px solid #171717;
  padding: 7px;
}

.input::placeholder {
  color: #171717;
}

.label {
  font-weight: 500;
}

#loginBtn {
  background-color: #111;
  border: 1px solid #fff;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#eyeOpenIcon {
  position: absolute;
  right: 23px;
  top: 43px;
}

#eyeCloseIcon {
  position: absolute;
  right: 23px;
  top: 43px;
}
#homesignup {
  font-weight: 400;
}
#homesignup span {
  color: #171717;
  font-weight: 500;
  cursor: pointer;
}
.logincol {
  width: 100%;
}
@media (max-width: 767px) {
  #eyeOpenIcon {
    position: absolute;
    right: 10px;
    top: 45px;
  }

  #eyeCloseIcon {
    position: absolute;
    right: 10px;
    top: 43px;
  }
  #loginDiv {
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #1f1f1f;
    width: 100%;
  }

  #loginHeading {
    font-size: 2rem;
    font-weight: 600;
    color: #1f1f1f;
    margin-top: -80px;
  }
}
.otpBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 8px;
  font-weight: 500;
  cursor: pointer;
}
