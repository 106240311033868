/* OrderModal.css */
.om-modal .modal-content {
  border-radius: 16px;
  border: none;
  background: #fff;
}

.om-modal-content {
  padding: 0;
}

.om-modal-header {
  background: #f4f4f2;
  padding: 1.25rem;
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #111;
}

.om-modal-header h4 {
  color: #111;
  margin: 0;
  font-weight: 500;
}

.om-close-btn {
  background: none;
  border: none;
  font-size: 1.25rem;
  padding: 0.25rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.om-close-btn:hover {
  opacity: 0.8;
}

.om-modal-body {
  padding: 1.5rem;
}

.om-order-summary {
  background: #f8f8f8;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 2rem;
}

.om-order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.om-order-id {
  font-size: 1.1rem;
  font-weight: 500;
  color: #111;
}

.om-order-amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2d3748;
}

.om-order-address {
  border-top: 1px solid #e2e8f0;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.om-order-address h6 {
  color: #dc2329;
  margin-bottom: 0.5rem;
}

.om-order-address p {
  color: #4a5568;
  font-size: 0.9rem;
  margin: 0;
}

.om-download-btn {
  background: #111;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.om-download-btn:disabled {
  background: #222;
  cursor: not-allowed;
}

.om-spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}

.om-progress-section {
  margin-bottom: 2rem;
}

.om-products-section h5 {
  color: #111;
  margin-bottom: 1rem;
}

.om-products-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.om-product-card {
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.om-product-card:hover {
  background: #f8f8f8;
  transform: translateY(-2px);
}

.om-product-image {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.om-product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.om-product-details {
  flex: 1;
}

.om-product-details h6 {
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.om-product-id {
  display: block;
  color: #718096;
  font-size: 0.85rem;
  margin-bottom: 0.75rem;
}

.om-product-desc {
  color: #4a5568;
  font-size: 0.9rem;
  line-height: 1.5;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .om-modal-body {
    padding: 1rem;
  }

  .om-product-card {
    flex-direction: column;
    gap: 1rem;
  }

  .om-product-image {
    width: 100%;
    height: 200px;
  }
}
