/* OrderCard.css */
.dash-order-card {
  background-color: white;
  border-radius: 8px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  transition: all 0.2s ease;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}

.dash-order-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-color: #e8e8e8;
}

.dash-order-image-container {
  width: 100%;
  aspect-ratio: 1;
}

.dash-order-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  cursor: pointer;
}
.dash-order-image:hover {
  transform: scale(1.05);
  transition: all 0.2s ease;
}

.dash-order-content {
  padding: 0 0.5rem;
}

.dash-order-title {
  font-size: 0.95rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: #2d3748;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dash-order-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.25rem;
}

.dash-order-id {
  font-size: 0.8rem;
  color: #718096;
}

.dash-order-price {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2d3748;
}

.dash-order-address {
  font-size: 0.8rem;
  color: #4a5568;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dash-order-side-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}

.dash-order-date {
  font-size: 0.75rem;
  color: #718096;
}

.dash-order-status-badge {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  padding: 0.35rem 0.65rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.dash-order-status-badge.pending {
  background-color: #ffe4e5;
  color: #dc2329;
}

.dash-order-status-badge.delivered {
  background-color: #f0fdf4;
  color: #15803d;
}

.dash-order-status-badge.shipping {
  background-color: #eff6ff;
  color: #1d4ed8;
}

.dash-order-status-badge.processing {
  background-color: #f5f3ff;
  color: #6d28d9;
}

.dash-order-status-badge span {
  text-transform: capitalize;
}

.dash-order-status-icon {
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .dash-order-card {
    padding: 0.65rem;
  }

  .dash-order-content {
    padding: 0 0 0 0.5rem;
  }

  .dash-order-side-content {
    margin-top: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
