/* Main container */
.sbcc-container {
  border-top: 2px solid #f4f4f2;
  text-align: center;
  padding: 20px 0;
}

/* Title */
.sbcc-title-container {
  margin: 20px 0;
}

.sbcc-title {
  font-weight: 600;
  color: #251c1a;
  font-size: 1.8rem;
}

/* Category Card */
.sbcc-card-col {
  display: flex;
  justify-content: center;
}

.sbcc-card-link {
  text-decoration: none;
  width: 100%;
}

.sbcc-card {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.sbcc-card:hover {
  transform: scale(1.01);
}

/* Card Image */
.sbcc-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Overlay */
.sbcc-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: background 0.3s ease-in-out;
}

.sbcc-card:hover .sbcc-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
}

/* Text Container */
.sbcc-text {
  text-align: center;
  color: #ffffff;
  padding: 15px;
  width: 100%;
}

/* Category Title */
.sbcc-category-title {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

/* Tagline */
.sbcc-tagline {
  font-size: 0.9rem;
  font-weight: 400;
  opacity: 0.85;
  margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sbcc-card {
    height: 250px;
  }

  .sbcc-overlay {
    height: 50%;
  }

  .sbcc-category-title {
    font-size: 1rem;
  }

  .sbcc-tagline {
    font-size: 0.8rem;
  }
}
