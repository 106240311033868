.product-gallery-container {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 100%;
}

/* Thumbnail Gallery (Desktop - Vertical) */
.thumbnail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 500px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, border 0.3s;
}

.thumbnail:hover,
.thumbnail.selected {
  transform: scale(1.1);
  /* border-bottom: 2px solid #007bff; */
}

/* Main Image Container */
.main-image-container {
  width: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}

/* No Image Text */
.no-image-text {
  font-size: 16px;
  color: #888;
}

/* RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .product-gallery-container {
    flex-direction: column;
    align-items: center;
  }

  .thumbnail-container {
    flex-direction: row;
    overflow-x: auto;
    max-width: 90%;
    max-height: none;
    justify-content: center;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
  }

  .main-image-container {
    width: 100%;
  }
}
