/* ProgressBar.css */
.progress-tracker {
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 2px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.step-circle svg {
  width: 20px;
  height: 20px;
}

.step-circle.active {
  border-color: #0d6efd;
  color: #0d6efd;
  background: #f0f7ff;
}

.step-circle.completed {
  background: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.connector-line {
  top: 20px;
  left: 50%;
  right: -50%;
  height: 2px;
  background: #dee2e6;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.connector-line.completed {
  background: #0d6efd;
}

.step-label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  text-transform: capitalize;
}

.step-description {
  font-size: 12px;
  color: #6c757d;
  margin-top: 4px;
}

/* Hover effects */
.step-circle:hover {
  transform: scale(1.05);
}

/* Animation for status change */
@keyframes statusComplete {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.step-circle.completed {
  animation: statusComplete 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .progress-tracker {
    padding: 1rem;
  }

  .step-circle {
    width: 32px;
    height: 32px;
  }

  .step-circle svg {
    width: 16px;
    height: 16px;
  }

  .connector-line {
    top: 16px;
  }

  .step-label {
    font-size: 12px;
  }

  .step-description {
    display: none;
  }
}
