/* Bento Grid Layout */
.bento-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 6fr 4fr; */
  gap: 10px;
  margin: 0 auto;
  height: 70%;
  padding: 1rem;
}

/* Left Section (60%) */
.bento-x-section {
  /* display: grid;
  grid-template-rows: 1fr 1fr; */
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 10px;
}
.bento-x-section > .x1 {
  width: 60%;
  /* background-color: aqua; */
}
.bento-x-section > .x2 {
  width: 40%;
}

/* Two small images in Left Section */
.bento-x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

/* Right Section (40%) */
.bento-y-section {
  /* display: grid;
  grid-template-rows: 1fr 1fr; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.bento-y-section > .y1 {
  width: 40%;
}
.bento-y-section > .y2 {
  width: 60%;
}

/* Common Styling for Bento Items */
.bento-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #f8f9fa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.bento-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.bento-item:hover img {
  transform: scale(1.05);
}

/* Overlay Styling */
.bento-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .bento-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }

  .bento-x-section {
    grid-template-rows: auto;
  }

  .bento-x2 {
    grid-template-columns: 1fr;
  }
}
