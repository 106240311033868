#landingDiv {
}

.browse {
  font-size: xx-large;
  color: #642315;
  font-weight: 500;
  margin-top: 4vh;
}
.choosecategory {
  cursor: pointer;
}

.maincontainer {
  height: 100px;
  width: 80px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  transition: all 0.3s ease-in-out;
}

.background {
  width: 70px;
  height: 90px;
  border-radius: 20px;
  background-color: rgba(239, 239, 162, 0.515);
  overflow: hidden;
  border-top-left-radius: 60px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 0.3s ease-out;
}

.subcontainer {
  height: 110px;
  width: 70px;
  position: absolute;
  bottom: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 7px;
}

.subcontainer p {
  font-size: 10px;
  margin-top: 0px;
  padding: 0 4px;
}

.row2 {
  margin-top: 40px;
}

.containt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.con {
  text-align: center;
  overflow: hidden;
}

.landing-line {
  background-color: #111;
  width: 100%;
  height: 1px;

  margin: 3rem 0rem;
}

.row2 {
  margin-left: 20px;
  margin-right: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.landingLstRow {
  row-gap: 40px;
  width: 100%;
  display: grid;
  justify-content: center;
  place-items: center;
}

.mainframeborder {
  width: 100%;
  background-color: #9a5d24;
  padding: 5vw;
}

.mainframeborderp {
  font-size: xx-large;
  color: white;
  font-weight: 500;
}

.mainframebordercontaint {
  color: white;
  font-size: medium;
  margin-top: 3vh;
}

.mainframeborderbutton {
  font-weight: 400;
  margin-top: 8vh;
  border-color: none;
  width: fit-content;
  border-radius: 27px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  color: black;
  border: none;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-bottom: 2.8rem;
  /* background-color: white; */
}
.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 50px;
  margin-left: 10px;
}
.frametext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #eaa6ab; */
  height: 12px;
  width: 300px;
  padding: 50px;
}

@media (max-width: 600px) {
  .landingLstRow {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    place-items: center;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 30px;
  }
  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
  }
  .frametext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 312px;
    width: 300px; */
    padding: 50px;
    width: 100%;
  }
  .frametext h3 {
    font-size: 16px;
  }
  .frametext p {
    font-size: 13px;
  }
  .frameimage {
    height: 200px;
    width: 300px;
  }
}
@media (min-width: 600px) {
  .landingLstRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    place-items: center;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-left: 110px;
  }
  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
  }
  .frametext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #f4f4f2; */
    height: 312px;
    width: 400px;
    padding: 50px;
  }
  .frameimage {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .landingLstRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-left: 110px;
  }
  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
  }
  .frametext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f4f4f2;
    height: 300px;
    width: 450px;
    padding: 50px;
  }
  .frameimage {
    height: 300px;
    width: 450px;
  }
}
@media (min-width: 992px) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-left: 110px;
  }
  .landingLstRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 50px;
  }
  .frameimage {
    height: 410px;
    width: 600px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .frametext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f4f4f2;
    height: 410px;
    width: 600px;
    padding: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
@media (min-width: 1500px) {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-left: 110px;
  }
  .landingLstRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 50px;
  }
  .frameimage {
    height: 500px;
    width: 700px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .frametext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f4f4f2;
    height: 500px;
    width: 450px;
    padding: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.colomn-2 {
  padding: 0;
  border-radius: 20px;
}

.colomn-1 {
  padding: 0;
  border-radius: 20px;
}
.col2 {
  margin: 20px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.productcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 380px;
  width: 200px;
  background-color: #f4f4f2;
  border-radius: 20px;
}
.productimage {
  margin-bottom: 1px;
  height: 260px;
  width: 180px;
  padding-top: 5px;
}
.image {
  height: 250px;
  width: 180px;
  border-radius: 20px;
}
.productname {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 0px;
  padding: 0 4px;
  text-align: center;
}
.productprice {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  /* letter-spacing: 1px; */
}
.product-details {
  position: absolute;
  bottom: 5px;
}
.cardtext {
  margin: 20px;
}
.cardtitle {
  color: #642315;
  font-weight: 700;
}

#msgBtn {
  border: none;
  cursor: pointer;
  position: fixed;
  top: 85%;
  right: 0;
  background-color: transparent;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4f4f2;
  /* background-color: red; */
  padding: 1%;
  display: flex;
  right: 2%;
  justify-content: center;
  align-items: center;
}

.MessageIconContainer {
  width: 100px !important;
  height: 100px;
  color: black;
}
#landingLstRow {
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  .equal-height > [class*="col-"] {
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  .colomn-2 {
    width: 280px;
  }
  .equal-height > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .equal-height > [class*="col-"] > * {
    flex: 1;
  }
}

.productImg {
  height: 300px !important;
  border-radius: 10px;
}

/* browse by  category styling */
.background-image {
  width: 100%;
  height: auto;
}

.category-image {
  width: 80px;
  height: 70px;
  object-fit: contain;
  border-radius: 3px;
}
@media (min-width: 1200px) {
  #msgBtn {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 500px) {
  #msgBtn {
    width: 50px;
    height: 50px;
    padding: 3%;
  }
}
